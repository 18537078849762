import React from 'react'
import './categories.css'
import Portada1 from './assets/portadas/P01.jpg'
import Portada2 from './assets/portadas/P02.jpg'
import Portada3 from './assets/portadas/P03.jpg'
import Portada4 from './assets/portadas/P04.jpg'
import Portada5 from './assets/portadas/P05.jpg'
import Portada6 from './assets/portadas/P06.jpg'
import Portada7 from './assets/portadas/P07.jpg'
import Portada8 from './assets/portadas/P08.jpg'
import Portada9 from './assets/portadas/P09.jpg'
import Portada10 from './assets/portadas/P10.jpg'
import Portada11 from './assets/portadas/P11.jpg'
import Wats from './assets/utils/logo-wasap.png'
import P1 from './assets/productos/mexico/mx1.png'
import P2 from './assets/productos/mexico/mx2.png'
import P3 from './assets/productos/mexico/mx3.png'
import P4 from './assets/productos/mexico/mx4.png'
import P5 from './assets/productos/mexico/mx5.png'
import P6 from './assets/productos/per/per1.png'
import P7 from './assets/productos/per/per2.png'
import P8 from './assets/productos/per/per3.png'
import P9 from './assets/productos/per/per4.png'
import P10 from './assets/productos/per/per5.png'
import P11 from './assets/productos/mini/mini1.png'
import P12 from './assets/productos/mini/mini2.png'
import P13 from './assets/productos/comics/comic1.png'
import P14 from './assets/productos/comics/comic2.png'
import P15 from './assets/productos/f1/f1.png'
import P16 from './assets/productos/nfl/nfl1.png'
import P17 from './assets/productos/nfl/nfl2.png'
import P18 from './assets/productos/nfl/nfl3.png'
import P19 from './assets/productos/nfl/nfl4.png'
import P20 from './assets/productos/nba/nba1.png'
import P21 from './assets/productos/nba/nba2.png'
import P22 from './assets/productos/nba/nba3.png'
import P23 from './assets/productos/nba/nba4.png'
import P24 from './assets/productos/fifa/fifa1.png'
import P25 from './assets/productos/fifa/fifa2.png'
import P26 from './assets/productos/fifa/fifa3.png'
import P27 from './assets/productos/fifa/fifa4.png'
import P28 from './assets/productos/nhl/nhl1.png'
import P29 from './assets/productos/nhl/nhl2.png'
import P30 from './assets/productos/nhl/nhl3.png'
import P31 from './assets/productos/nhl/nhl4.png'
import P32 from './assets/productos/comics/comic3.png'
import P33 from './assets/productos/comics/comic4.png'
import P34 from './assets/productos/disney/disney1.png'
import P35 from './assets/productos/disney/disney2.png'
import P36 from './assets/productos/princesas/princesas1.png'
import P37 from './assets/productos/princesas/princesas2.png'
import P38 from './assets/productos/princesas/princesas3.png'
import P39 from './assets/productos/princesas/princesas4.png'
import P40 from './assets/productos/princesas/princesas5.png'
import P41 from './assets/productos/princesas/princesas6.png'
import P42 from './assets/productos/princesas/princesas7.png'
import P43 from './assets/productos/princesas/princesas8.png'
import P44 from './assets/productos/princesas/princesas9.png'
import P45 from './assets/productos/princesas/princesas10.png'

import Footer from './Footer'
import Card from './Card'

const Categories = () => {
    const portadas = [
    {
        id: 1,
        img : Portada1,
        titulo: "Souvenirs México",
        subtitilo: "Rejoles y accesorios",
        productos: [
        {
            img: P1,
            nombre: "Cuelga Llaves"
        },
        {
            img: P2,
            nombre: "Relojes"
        },
        {
            img: P3,
            nombre: "Llaveros"
        }
        ,
        {
            img: P4,
            nombre: "Imanes"
        }
        ,
        {
            img: P5,
            nombre: "Plumas"
        }
        ]
    },
    {
        id: 2,
        img : Portada2,
        titulo: "Personalizados",
        subtitilo: "Rejoles y accesorios",
        productos: [
        
            {
                img: P6,
                nombre: "Cuelga Llaves"
            },
            {
                img: P7,
                nombre: "Relojes"
            },
            {
                img: P8,
                nombre: "Llaveros"
            }
            ,
            {
                img: P9,
                nombre: "Imanes"
            }
            ,
            {
                img: P10,
                nombre: "Plumas"
            }
        ]
    },
    {
        id: 3,
        img : Portada3,
        titulo: "Minimalistas",
        subtitilo: "Rejoles y accesorios",
        productos: [
            {
                img: P11,
                nombre: "Relojes"
            },
            {
                img: P12,
                nombre: "Relojes de Pared"
            },
         
        ]
    },
    {
        id: 4,
        img : Portada4,
        titulo: "Cómics",
        subtitilo: "Rejoles y accesorios",
        productos: [
            {
                img: P13,
                nombre: "Relojes"
            },
            {
                img: P14,
                nombre: "Relojes"
            }
            ,
            {
                img: P32,
                nombre: "Relojes"
            }
            ,
            {
                img: P33,
                nombre: "Relojes"
            }
        ]
    },
    {
        id: 5,
        img : Portada5,
        titulo: "Fórmula 1",
        subtitilo: "Rejoles y accesorios",
        productos: [
            {
                img: P15,
                nombre: "Relojes"
            }
        ]
    },
    {
        id: 6,
        img : Portada6,
        titulo: "NFL",
        subtitilo: "Rejoles y accesorios",
        productos: [
            {
                img: P16,
                nombre: "Cuelga Llaves"
            },
            {
                img: P17,
                nombre: "Relojes"
            },
            {
                img: P18,
                nombre: "Llaveros"
            }
            ,
            {
                img: P19,
                nombre: "Imanes"
            }
            ,
            
        ]
    },
    {
        id: 7,
        img : Portada7,
        titulo: "NBA",
        subtitilo: "Rejoles y accesorios",
        productos: [
            {
                img: P20,
                nombre: "Cuelga Llaves"
            },
            {
                img: P21,
                nombre: "Relojes"
            },
            {
                img: P22,
                nombre: "Llaveros"
            }
            ,
            {
                img: P23,
                nombre: "Imanes"
            }
            ,
            
        ]
    },
    {
        id: 8,
        img : Portada8,
        titulo: "FIFA",
        subtitilo: "Rejoles y accesorios",
        productos: [ {
            img: P24,
            nombre: "Cuelga Llaves"
        },
        {
            img: P25,
            nombre: "Relojes"
        },
        {
            img: P26,
            nombre: "Llaveros"
        }
        ,
        {
            img: P27,
            nombre: "Imanes"
        }
        
        ]
    },
    {
        id: 9,
        img : Portada9,
        titulo: "NHL",
        subtitilo: "Rejoles y accesorios",
        productos: [
        {
              img: P28,   
            nombre: "Cuelga Llaves"
        },
        {
              img: P29,
            nombre: "Llaveros"
        },
        {
              img: P30,
            nombre: "Imanes"
        },
        {
              img: P31,
            nombre: "Relojes"
        }
        ]
    },
    {
        id: 10,
        img : Portada10,
        titulo: "DISNEY",
        subtitilo: "Rejoles y accesorios",
        productos: [
        {
              img: P34,   
            nombre: "Relojes"
        },
        {
              img: P35,
            nombre: "Relojes"
        },
        
        ]
    },
    {
        id: 11,
        img : Portada11,
        titulo: "Princesas",
        subtitilo: "Rejoles y accesorios",
        productos: [
        {
              img: P36,   
            nombre: "Relojes"
        },
        {
              img: P37,
            nombre: "Relojes"
        },
        {
            img: P38,
          nombre: "Relojes"
      },
      {
        img: P39,
      nombre: "Relojes"
  },
  {
    img: P40,
  nombre: "Relojes"
},
{
    img: P41,
  nombre: "Relojes de pared"
},
{
    img: P42,
  nombre: "Relojes de pared"
},
{
    img: P43,
  nombre: "Relojes de pared"
},
{
    img: P44,
  nombre: "Relojes de pared"
},
{
    img: P45,
  nombre: "Relojes de pared"
},
        
        ]
    },
    ]
  return (
    <>
    <div className="landingpage">
  <div className="discover">
    <div className="discover-title">
      <p>Productos</p>
      
    </div>
    <div className="discover-items">
        {portadas.map((portada =>(
       <Card key={portada.id} titulo={portada.titulo} img={portada.img} subtitulo={portada.subtitilo} productos={portada.productos}/>     
            )))}
     
    
    </div>
    
  </div>
 <Footer/>
</div>

<a href="https://wa.me/529842393178/?text=Quisiera%20más%20información" className='logow' target="_blank">
    <img src={Wats} width="60" height="60"/>
 </a>
    </>
  )
}

export default Categories