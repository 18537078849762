import React from 'react'
import LogoFooter from './assets/logos/Logoblanco.png'
import './categories.css'
const Footer = () => {
  return (
    <div class="footer">
    <div class="footer-main">
      <img src={LogoFooter} width="200"  alt="" srcset="" />
    </div>
    <div class="footer-navigate">
    

      <div class="nav">
        <h5>Contacto</h5>
        <a href = "mailto: info@redimex.net"> <p>info@redimex.net</p></a>
       
       
      </div>
    </div>
  </div>
  )
}

export default Footer