import React from 'react'
import Body from './components/Body'
import Categories from './components/Categories'
import Hero from './components/Hero'

const App = () => {
  return (
    <>
    
    <Hero/>
 <Categories/>
 <Body/>
    </>
  )
}

export default App