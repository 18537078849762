import React from 'react'
import './hero.css'
import Logo from './assets/logos/LogoRDMblanco.png'
import Letras from './assets/logos/heroletras.png'
const Hero = () => {
    const navbarMenu = document.getElementById("menu");
const burgerMenu = document.getElementById("burger");
const headerMenu = document.getElementById("header");

// Open Close Navbar Menu on Click Burger
if (burgerMenu && navbarMenu) {
   burgerMenu.addEventListener("click", () => {
      burgerMenu.classList.toggle("is-active");
      navbarMenu.classList.toggle("is-active");
   });
}

// Close Navbar Menu on Click Menu Links
document.querySelectorAll(".menu-link").forEach((link) => {
   link.addEventListener("click", () => {
      burgerMenu.classList.remove("is-active");
      navbarMenu.classList.remove("is-active");
   });
});

// Change Header Background on Scrolling
window.addEventListener("scroll", () => {
   if (this.scrollY >= 85) {
      headerMenu.classList.add("on-scroll");
   } else {
      headerMenu.classList.remove("on-scroll");
   }
});

  return (
   <>
<header className="header" id="header">
   <nav className="navbar container">
      <a  className="brand">REDIMEX</a>
      <div className="burger" id="burger">
         <span className="burger-line"></span>
         <span className="burger-line"></span>
         <span className="burger-line"></span>
      </div>
      <div className="menu" id="menu">
         <ul className="menu-inner">
            <li className="menu-item"><a href="#" className="menu-link"></a></li>
            <li className="menu-item"><a href="#" className="menu-link"></a></li>
            <li className="menu-item"><a href="#" className="menu-link"></a></li>
            <li className="menu-item"><a href="#" className="menu-link"></a></li>
         </ul>
      </div>
      {
      /**
       * <a href = "mailto: info@redimex.net" className="menu-block">catálogo</a>
       * 
       */}
      
   </nav>
</header>

<main className="main">
   <section className="section banner banner-section">
      <div className="container banner-column">
         <img className="banner-image" src={Logo} alt="banner"/>
         <div className="banner-inner">
            {/**
             * 
             * 
            <h1 className="heading-xl">REDIMEX</h1>
             */}
            
             <img src={Letras} alt="" srcset="" />
            <p className="paragraph">
            Somos una empresa mexicana comprometida con la calidad de nuestros productos.  Diseñamos y fabricamos accesorios decorativos con valor artesanal.
            <br/>
           Te invitamos a trabajar juntos creando un concepto personalizado de acuerdo a tus necesidades.
            </p>
               
           
          
         </div>
         <div className="banner-links">
            <a href="#" title=""><i className="bx bxl-facebook"></i></a>
            <a href="#" title=""><i className="bx bxl-instagram"></i></a>
            <a href="#" title=""><i className="bx bxl-twitter"></i></a>
            <a href="#" title=""><i className="bx bxl-youtube"></i></a>
         </div>
      </div>
   </section>
</main>

   </>
  )
}

export default Hero