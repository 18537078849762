import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import './card.css'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Card = ({titulo, img, subtitulo, productos}) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  return (
    <>
    
    <div className="item" onClick={handleClickOpen}>
        <img className="item-img" src={img} alt="unsplash-OG44d93i-NJk" border="0"/>
        <div className="item-title">
          <p>{titulo}</p>
        </div>
        <p className="item-date">{subtitulo}</p>
      </div>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'fixed', backgroundColor: 'black' }}>
          <Toolbar>
          <button  onClick={handleClose}>X</button>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {titulo}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
             Cerrar
            </Button>
          </Toolbar>
        </AppBar>
        <section class="product-section">
  <div class="product-grid">
 {productos.map((producto=>(
    
    <div class="card">
        {/**
         *  <div class="card-pill">
        Sale
      </div>
         * 
         */}
     
      <img class="card-img" src={producto.img} alt="product-image"/>
      <div class="flex-row space-between w-full mb-sm">
        <p class="product-brand"></p>
        <p class="product-cat hide">Skateboarding</p>
      </div>
      <h1 class="product-name">{producto.nombre}</h1>
     {/*
 <div class="flex-row">
        <p class="price strike">$<span>94.95</span></p>
        <p class="price">$<span>79.95</span></p>
      </div>
     */}
     
      <div class="btn-col">
        <a href={`https://wa.me/529842393178/?text=Quisiera%20más%20información%20sobre%20${producto.nombre}%20-%20${titulo}`} target="_blank" class="icon-link">
          Más Información
          <svg fill="none" class="rubicons arrow-right-up" xmlns="http://www.w3.org/2000/svg" width="auto" height="auto" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path d="M17.9645 12.9645l.071-7h-7.071" stroke-linecap="round"></path>
            <path d="M5.9645 17.9645l12-12" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </a>
      </div>
    </div>
    )))}
    
  </div>
</section>
      </Dialog>
    </>
    
  )
}

export default Card